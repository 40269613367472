import { IGroupMapping, IFieldValueGroupMapping, IFieldValueGroupMappingRow } from "../models/models";


export const getMappingGroupMappings = (systemId: number,
    fromGroupMappings: Array<IGroupMapping>,
    fieldValueFromGroupMappings: Array<IFieldValueGroupMapping>,
    fieldValueToGroupMappings: Array<IFieldValueGroupMapping>) => {

    var groups = fromGroupMappings?.filter((group) => group.fromSystemId === systemId);

    const mappingData = new Map<string, Map<number, IFieldValueGroupMappingRow>>();

    groups?.forEach((group) => {

        const fromGroupMappings = fieldValueFromGroupMappings
            .filter((mapping) => mapping.groupId === group.fromGroupId)
            .sort((a, b) => a.fieldId - b.fieldId);
        const toGroupMappings = fieldValueToGroupMappings.filter((mapping) => mapping.groupId === group.toGroupId);

        let mappingKey = '';
        const mappingKeys = new Array<string>();
        for (let i = 0; i < fromGroupMappings.length; i++) {
            let mapping = fromGroupMappings[i]?.fieldName;
            mappingKeys.push(mapping)
        }
        mappingKey = mappingKeys.sort((a, b) => a.localeCompare(b)).join(" - ");

        const keyValue = mappingData.get(mappingKey);
        const row = {
            id: group.fromGroupId,
            fromGroupMappings: fromGroupMappings,
            toGroupMappings: toGroupMappings
        } as IFieldValueGroupMappingRow;
        if (keyValue) {
            keyValue.set(group.fromGroupId, row);
        } else {
            const map = new Map<number, IFieldValueGroupMappingRow>();
            map.set(group.fromGroupId, row);
            mappingData.set(mappingKey, map);
        }
    });

    var keys = Array.from(mappingData.keys());
    keys.forEach(key => {
        const mapValue = mappingData.get(key);

        if (mapValue) {
            const mapKeys = Array.from(mapValue.keys());
            mapKeys.forEach(mapKey => {
                const row = mapValue.get(mapKey);

                if (row) {
                    if (row.toGroupMappings.length === 0) {
                        mapValue.delete(mapKey);
                    }
                }
            });
        }
    });

    keys.forEach(key => {
        if (mappingData.get(key)?.size === 0) {
            mappingData.delete(key);
        }
    });
    return mappingData;
}
