import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetGroupMappings } from "../../api/groupMappings";
import { useGetFieldValueGroupMappings } from "../../api/fieldValueGroupMappings";
import { getMappingGroupMappings } from "../../utils/getMappingsPerMappingGroup";
import { IFieldValueGroupMappingRow } from "../../models/models";
import ExchangeDetailsHeader from "../../components/ExchangeDetailsHeader";
import { useAuth } from "oidc-react";
import isUserAdmin from "../../utils/isUserAdmin";
import { FullPageLoader } from "../../components/FullPageLoader";
import MappingGroupDetails from "./components/MappingGroupDetails";
import React from "react";
import readUserProfile from "../../utils/readUserProfile";

const ExchangeDetails = () => {
  const { _, exchangeId } = useParams();

  const auth = useAuth();
  const isAdmin = isUserAdmin(auth.userData);
  const userProfile = readUserProfile(auth);

  const [info, setInfo] = useState<Map<string, Map<number, IFieldValueGroupMappingRow>>>();
  const [selectOptions, setSelectOptions] = useState<string[]>();
  const [isAlertHidden, setAlertHidden] = useState<{ hidden: boolean; message: string; alertType: string; }>({ hidden: true, message: "", alertType: "information" });

  const { data: allGroupMappings, isLoading: groupMappingsLoading  } = useGetGroupMappings();
  const { data: fieldValueFromGroupMappings, isLoading: fieldValueLoading } = useGetFieldValueGroupMappings(Number(exchangeId), true);

  const firstMapping = allGroupMappings?.find((group) => group.fromSystemId === Number(exchangeId));
  const { data: fieldValueToGroupMappings, isLoading: fieldValueGroupMappingsLoading } = useGetFieldValueGroupMappings(firstMapping?.toSystemId ?? 0, firstMapping);

  let groupMappingsProcessing = false;

  useEffect(() => {
    if (allGroupMappings && fieldValueFromGroupMappings && fieldValueToGroupMappings) {
      groupMappingsProcessing = true;

      const numExchangeId = Number(exchangeId);

      const totalGroupMappings = allGroupMappings ?? [];

      const groupMappings = getMappingGroupMappings(numExchangeId, totalGroupMappings, fieldValueFromGroupMappings ?? [], [...(fieldValueToGroupMappings ?? []), ...(fieldValueFromGroupMappings ?? [])]);
      const sOptions = Array.from(groupMappings.keys());

      setInfo(groupMappings);
      setSelectOptions(sOptions);
      groupMappingsProcessing = false;
    }
  }, [groupMappingsLoading, fieldValueFromGroupMappings, fieldValueToGroupMappings]);

 

  if(groupMappingsLoading || fieldValueLoading || fieldValueGroupMappingsLoading || groupMappingsProcessing) {
    return ( <FullPageLoader />);
  }

  if (info && selectOptions) {
    return (
      <>
        <div style={{ position: "relative", backgroundColor: "white" }}>
          <ExchangeDetailsHeader isAlertHidden={isAlertHidden} setAlertHidden={setAlertHidden} />

          <MappingGroupDetails
            mappingGroupInfo={info}
            mappingGroupOptions={selectOptions}
            setAlertHidden={setAlertHidden}
            setInfo={setInfo}
            isAdmin={isAdmin}
            currentUser={userProfile?.fullName}
          />

        </div>
      </>
    );
  } else {
    return null;
  }
};

export default ExchangeDetails;
