import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import GlobalHeader from "../components/GlobalHeader";
import pageLayout from "../assets/css/page-layout.module.css";
import Schedules from "./Schedules/Schedules";
import Exchanges from "./Exchanges/Exchanges";
import ExchangeDetails from "./ExchangeDetails/ExchangeDetails";
import { useAuth } from "oidc-react";
import AuthInProgress from "./Auth/AuthInProgress";
import NotAuthorized from "./Auth/NotAuthorized";
import parseJwt from "../utils/parseJwt";
import ExchangeInfo from "./ExchangeInfo/ExchangeInfo";
import { FieldContext } from "../Context";
import { useEffect, useState } from "react";
import { getAllFields } from "../utils/getAllFields";
import LoggedOut from "./Auth/LoggedOut";

export const ROUTES = {
  home: "/",
  dashboard: "dashboard",
  userAdmin: "user-admin",
  schedules: "schedules",
  exchangeDetails: "exchange-details/:exchangeName/:exchangeId",
  exchangeInfo: "exchange-info/:exchangeName/:exchangeId",
  notAuthorized: "not-authorized",
  loggedOut: "logged-out",
};

const StartPage = () => {

  const { page, pageBody } = pageLayout;
  const auth = useAuth();

  const [value, setValue] = useState({});

  useEffect(() => {
    if (auth.userData) {
      getAllFields().then(data => { setValue(data); }, () => { console.log("error") });
    }    
  }, [auth.userData]);

 
  if (auth.isLoading) {
    return <AuthInProgress />
  }

  if (!auth.userData) {
    return <AuthInProgress />
  }

  if (auth.userData) {
    const membershipGroups = ['AZ-AS-GRP-SG-N-DS-11d04c0644dc4ed386f5', 'AZ-AS-GRP-SG-N-DS-7df2c52618a14fd0adcd'];
    const accessToken = parseJwt(auth.userData.access_token);
    if (accessToken) {
      let isInRole = false;
      const membership = (accessToken.Groups ?? []).join(',').toLowerCase();      
      for (let i = 0; i < membershipGroups.length; i++) {
        if (membership.indexOf(membershipGroups[i].toLowerCase()) > -1) {
          isInRole = true;
        }
      }
      if (!isInRole) {
        return <NotAuthorized />
      }
    }
  }

  if (window.location.href.indexOf(ROUTES.notAuthorized) > -1) {
    return <NotAuthorized />
  }

  
  return (
    <div className={page}>
      <FieldContext.Provider value={value}>
        <BrowserRouter>
          <div className="navbar" >
            <GlobalHeader />
          </div>
          <div className={pageBody} style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
            <Routes>
              <Route path={ROUTES.home} element={<Navigate to={ROUTES.dashboard} replace />} />
              <Route path={ROUTES.schedules} element={<Schedules />} />
              <Route path={ROUTES.dashboard} element={<Exchanges />} />
              <Route path={ROUTES.exchangeDetails} element={<ExchangeDetails />} />
              <Route path={ROUTES.exchangeInfo} element={<ExchangeInfo />} />
              <Route path={ROUTES.notAuthorized} element={<NotAuthorized />} />
              <Route path={ROUTES.loggedOut} element={<LoggedOut />} />
            </Routes>
          </div>
        </BrowserRouter>
      </FieldContext.Provider>
    </div>
  );
};

export default StartPage;
