import axios, { AxiosError } from "axios";
import { useQuery } from 'react-query';
import { IFieldValueGroup } from "../models/models";
import { getApiConfig } from "./apiConfig";

const getFieldValueGroups = async (): Promise<IFieldValueGroup[]> => {
  const config = getApiConfig();

  try {
    const response = await axios
      .get(`${config.url}/field-value-groups`, config.axiosConfig);

    return response?.data ?? null;
  } catch (err) {
    console.error('err', err);
    throw err;
  }
};


export const checkFieldValueGroupExists = async (systemId: number, fieldValueIds: Array<number>): Promise<boolean> => {

  const config = getApiConfig();

  let queryString = 'fieldValueIds=' + (fieldValueIds.join('&fieldValueIds='));

  try {
    const response = await axios
      .head(`${config.url}/systems/${systemId}/field-value-groups-mappings?${queryString}`, config.axiosConfig);

    return response?.status === 200;
  } catch (err: any) {
    if(err.response.status === 404) {
      return false;
    } else {
    console.error('err', err);
    throw err;
    }
  }
}


export const useGetFieldValueGroups = () => {
  return useQuery<IFieldValueGroup[], AxiosError>(
    [`fieldValueGroups-info`],
    () => getFieldValueGroups(),
  );
};

export const useCheckFieldValueGroupExists = (systemId: number, fieldValueIds: Array<number>) => {
  return useQuery<boolean, AxiosError>(
    [`checkFieldValueGroupExists`, systemId, fieldValueIds],
    () => checkFieldValueGroupExists(systemId, fieldValueIds),
  ); 
}

